import MainNav from '../main-nav/index';
import './style.scss';

function Header() {
  return (
    <>
      <header className="main-header" id="home">
        <div className="container">
          <div className="logo-wrapper">
            <span className="logo">Ellie Flach</span>
            <span className="logo-sub">Psychodynamic Psychotherapist</span>
          </div>
          <MainNav />
        </div>
        {/* <div className="app-header"> */}
        {/* </div> */}
      </header>
    </>
  );
}

export default Header;
