import Hero from './hero';
import TherapyDetails from './therapy-details';
import Helmet from 'react-helmet';
import './style.scss';
import { useInView } from 'react-intersection-observer';
function Home() {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  return (
    <main className={`home ${inView ? 'in-view' : ''}`} ref={ref}>
      <Helmet title="Ellie Flach Psychodynamic Psychotherapist - Home" />
      <Hero></Hero>
      <TherapyDetails></TherapyDetails>
    </main>
  );
}

export default Home;
