// import { useLocation, Switch, Route } from 'react-router-dom';
import './style.scss';
// import MainNav from '../main-nav/index';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import Home from '../home';
// import About from '../about';
// import PsychodynamicPsychotherapy from '../psychodynamicPsychotherapy';
// import Services from '../servicesAndFees';
// import Contact from '../contact/index';
import Footer from '../footer';

function Layout({ children }) {
  // let location = useLocation();
  return (
    <div className="layout">
      {children}
      {/* <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames="main-content"
          timeout={1000}
        >
          <Switch location={location}>
            <Route exact path="/" children={<Home />} />
            <Route path="/about" children={<About />} />
            <Route
              path="/psychodynamic"
              children={<PsychodynamicPsychotherapy />}
            />
            <Route path="/services" children={<Services />} />
            <Route path="/contact" children={<Contact />} />
          </Switch>
        </CSSTransition>
      </TransitionGroup> */}
      <Footer />
    </div>
  );
}

export default Layout;
