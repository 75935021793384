import './style.scss';

function Service({ service }) {
  return (
    <div className="service">
      <img src={service.image} alt="" />
      <div className="content">
        <h2 className="service-heading">{service.heading}</h2>
        <p>{service.paragraph}</p>
        {/* <span>{service.fee}</span> */}
      </div>
    </div>
  );
}

export default Service;
