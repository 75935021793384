import PageWrapper from '../page-wrapper';
import React from 'react';
import './style.scss';

const PageContent = React.forwardRef(
  ({ imageSrc, heading, bodyText, subheading, email, phone }, elRef) => {
    return (
      <PageWrapper>
        <div className="page-content" ref={elRef}>
          <div className="image">
            <img src={imageSrc} alt="" />
          </div>
          <div className="text">
            <h2>{heading}</h2>
            <h3>{subheading}</h3>
            {bodyText.map((paragraph) => {
              return <p key={paragraph}>{paragraph}</p>;
            })}
            {phone ? (
              <a
                rel="noopener"
                className="contact-details"
                href={`tel:+44${phone.replaceAll(' ', '')}`}
              >
                {phone}
              </a>
            ) : (
              ''
            )}
            {email ? (
              <a
                rel="noopener"
                className="contact-details"
                href={`mailto:${email}?subject=Therapy request`}
              >
                {email} &#x02197;
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
      </PageWrapper>
    );
  }
);

export default PageContent;
