import './style.scss';
import heroImage from '../../assets/images/profile.webp';
function Hero() {
  return (
    <div className="hero">
      <div className="hero-content">
        <div className="text">
          <div className="main-text">Ellie Flach</div>
          <div className="sub-text">
            <p>Psychodynamic Psychotherapist</p>
            <p>British Psychoanalytic Council Reg. 23811</p>
          </div>
        </div>
      </div>
      <img src={heroImage} alt="" />
    </div>
  );
}

export default Hero;
