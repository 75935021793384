import './style.scss';
import React from 'react';

const PageWrapper = React.forwardRef(({ children }, ref) => {
  return (
    <div className="page-wrapper" ref={ref}>
      {children}
    </div>
  );
});

export default PageWrapper;
