import Helmet from 'react-helmet';
import PageWrapper from '../page-wrapper';
import Service from './service';
import './style.scss';
import image1 from '../assets/images/daniele-levis-pelusi-Vey6fioB1eI-unsplash.jpg';
import image2 from '../assets/images/jeremy-bishop-hVkDaLkoiec-unsplash.jpg';
import { useInView } from 'react-intersection-observer';

const services = [
  {
    image: image1,
    heading: 'Psychotherapy',
    paragraph:
      'I offer open ended psychotherapy, with sessions once or twice a week. I usually work with people long term, however I am able to offer short term focussed work if preferred.  In our initial few meetings we will discuss the issues that have made you seek therapy and explore whether this kind of therapy is likely to be helpful for you, and if the way I work feels right for you.',
    fee: 'Once weekly sessions are £70.',
  },
  {
    image: image2,
    heading: 'Supervision',
    paragraph:
      'I offer clinical supervision for individual professional and unqualified clinicians employed by private and third sector organisations.  This might be ongoing and open ended, or you might prefer to book a set number of sessions to think through a particularly challenging piece of work, depending on your circumstances.',
    fee: 'Individual supervision is £60 per session.',
  },
];

function Services() {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  return (
    <PageWrapper>
      <Helmet title="Ellie Flach Psychodynamic Psychotherapist - Services & Fees" />
      <div className={`services ${inView ? 'in-view' : ''}`} ref={ref}>
        {services.map((service) => (
          <Service service={service} key={service.heading} />
        ))}
      </div>
    </PageWrapper>
  );
}

export default Services;
