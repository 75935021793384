import Image from '../assets/images/scott-webb-OxHPDs4WV8Y-unsplash.jpg';
import PageContent from '../page-content';
import { useInView } from 'react-intersection-observer';
import Helmet from 'react-helmet';

function Contact() {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const bodyText = ['', 'Ellie Flach'];
  const subheading =
    'If you would like to get in contact please email me using the details below.';
  const heading = 'Get in Touch';
  return (
    <div className={`contact ${inView ? 'in-view' : ''}`} ref={ref}>
      <Helmet title="Ellie Flach Psychodynamic Psychotherapist - Contact" />
      <PageContent
        imageSrc={Image}
        bodyText={bodyText}
        heading={heading}
        subheading={subheading}
        // phone="07712 735 962"
        email="contact@ellieflachtherapy.com"
      />
    </div>
  );
}

export default Contact;
