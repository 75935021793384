import Image from '../assets/images/sharon-mccutcheon-igBvG1AKTY8-unsplash.jpg';
import PageContent from '../page-content';
import Helmet from 'react-helmet';
import { useInView } from 'react-intersection-observer';

function PsychodynamicPsychotherapy() {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const bodyText = [
    'Symptoms such as anxiety, panic, persistent problems with anger or depression, unexplained physical symptoms or repetitive problems in relationships often signal that painful issues or anxieties from the past have not yet been worked through.',
    'In psychodynamic psychotherapy we will spend time working out what these issues and anxieties are, and work through them together in a safe space.  We will get a better understanding of why you are having the particular difficulties you are in the context of life experiences that are unique to you.  I will focus on helping you develop a fuller contact with the different aspects of yourself and challenge the parts of yourself that hold you back.',
  ];
  const subheading =
    'In psychodynamic psychotherapy we work out the unconscious meaning of why we feel, think and do what we do.';
  const heading = 'Psychodynamic Psychotherapy';
  return (
    <div className={`psychodynamic ${inView ? 'in-view' : ''}`} ref={ref}>
      <Helmet title="Ellie Flach Psychodynamic Psychotherapist - Therapy" />
      <PageContent
        imageSrc={Image}
        bodyText={bodyText}
        heading={heading}
        subheading={subheading}
      />
    </div>
  );
}

export default PsychodynamicPsychotherapy;
