import './style.scss';
import { useState } from 'react';
// import { a } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

function MainNav({ isHomeActive }) {
  const [navOpen, setNavOpen] = useState(false);
  const [active, setActive] = useState('home');
  const onToggle = (activeSection) => {
    setNavOpen((navOpen) => !navOpen);
    setActive(activeSection);
  };
  return (
    <>
      <nav className={`main-nav ${navOpen ? 'open' : ''}`}>
        <ul className={`main-nav-menu ${navOpen ? 'open' : ''}`}>
          <li>
            <a
              className={`${active === 'home' ? 'active' : ''}`}
              href="#home"
              onClick={() => onToggle('home')}
            >
              Home
            </a>
          </li>
          <li>
            <a
              className={`${active === 'about' ? 'active' : ''}`}
              href="#about"
              onClick={() => onToggle('about')}
            >
              About
            </a>
          </li>
          <li>
            <a
              className={`${active === 'psychodynamic' ? 'active' : ''}`}
              href="#psychodynamic"
              onClick={() => onToggle('psychodynamic')}
            >
              Psychodynamic Psychotherapy
            </a>
          </li>
          <li>
            <a
              className={`${active === 'services' ? 'active' : ''}`}
              href="#services"
              onClick={() => onToggle('services')}
            >
              Services
            </a>
          </li>
          <li>
            <a
              className={`${active === 'contact' ? 'active' : ''}`}
              href="#contact"
              onClick={() => onToggle('contact')}
            >
              Contact
            </a>
          </li>
        </ul>
      </nav>
      <MenuIcon className="hamburger" onClick={onToggle} />
      {!isHomeActive && (
        <>
          <span className="logo small-screens">Ellie Flach</span>
          <span className="logo-sub small-screens">
            Psychodynamic Psychotherapist
          </span>
        </>
      )}
    </>
  );
}

export default MainNav;
