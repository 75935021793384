import Image from '../assets/images/scott-webb-OxHPDs4WV8Y-unsplash.jpg';
import PageContent from '../page-content';
import Helmet from 'react-helmet';
import { useInView } from 'react-intersection-observer';

function About() {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '100px',
  });
  const bodyText = [
    'I trained at the Tavistock and Portman NHS Trust. I have worked therapeutically with people who have psychological and emotional difficulties for over fifteen years in a number of inpatient and community based NHS mental health services, mainly within South London and Maudsley NHS Trust.  I am experienced in working with adults and older adults andam particularly skilled in helping people with early trauma and disrupted early attachments, relationship difficulties, mood and anxiety problems and issues around loss.',
    'Since 2012 I have managed and clinically supervised NHS mental health clinicians from a range of professional backgrounds including psychological therapists, allied health professionals and mental health nurses who work in secondary care mental health services.',
    'As well as my private work I continue to work for the NHS and am currently based in Bath, South West England. ',
    'All of my clinical work is supervised and I have undergone extensive personal psychoanalysis as required by my training.',
  ];
  const subheading = 'I am a psychodynamic psychotherapist.';
  const heading = 'About';
  return (
    <div className={`about ${inView ? 'in-view' : ''}`} ref={ref}>
      <Helmet title="Ellie Flach Therapy - About" />
      <PageContent
        imageSrc={Image}
        bodyText={bodyText}
        heading={heading}
        subheading={subheading}
      />
    </div>
  );
}

export default About;
