import './app.scss';
import Header from './header';
import About from './about/index';
import Home from './home/index';
import Layout from './layout/index';
import PsychodynamicPsychotherapy from './psychodynamicPsychotherapy/index';
import Services from './servicesAndFees/index';
import Contact from './contact/index';

function App() {
  return (
    <>
      <div className="app" id="home">
        <Header />
        <Layout>
          <Home />
          <div id="about" className="page">
            <About />
          </div>
          <div id="psychodynamic" className="page">
            <PsychodynamicPsychotherapy />
          </div>
          <div id="services" className="page">
            <Services />
          </div>
          <div id="contact" className="page">
            <Contact />
          </div>
        </Layout>
      </div>
    </>
  );
}

export default App;
