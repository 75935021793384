import './style.scss';

function Footer() {
  return (
    <div className="footer">
      Ellie Flach &copy; {new Date().getFullYear()} -{' '}
      <a
        rel="noopener"
        className="contact-details"
        href={`mailto:contact@ellieflachtherapy.com?subject=Therapy request`}
      >
        Contact &#x02197;
      </a>
    </div>
  );
}

export default Footer;
