import './style.scss';
import { ReactComponent as Separator } from '../../assets/svg/separator.svg';
import Image from '../../assets/images/home.png';

function TherapyDetails() {
  return (
    <section className="therapy-details">
      {/* <h2 className="heading">Psychodynamic Psychotherapy</h2> */}
      <div className="therapy-details-content">
        <img src={Image} alt="" />
        <div>
          <h3>
            Psychodynamic psychotherapy is a type of psychoanalytic therapy.
          </h3>
          <p>
            It is usually open ended and long term. Using this approach I will
            aim to help you feel better and think more clearly by helping you to
            develop a deeper understanding of yourself including why you are
            having the particular difficulties you are having.
          </p>
          <p>
            This deeper understanding will be gained through exploring whatever
            is on your mind during each session. This will include making sense
            of unconscious thoughts such as dreams, as well as exploring and
            challenging patterns of relating that are repeated during the
            therapy.
          </p>
          <Separator />
        </div>
      </div>
    </section>
  );
}

export default TherapyDetails;
